// logo
#mpb_logo {
  width: 84px;
  padding-left: $space-small;
  @media (min-width: $small) {
    padding-left: 45px;
  }
  @media (min-width: $medium) {
    padding-left: $space-medium;
  }
  @media (min-height: $large) {
    padding-left: 40px;
  }
}

// hamburger
#hamburger {
  rect {
    fill: white;
  }
  padding-right: $space-small;
  @media (min-width: $small) {
    padding-right: 45px;
  }
  @media (min-width: $medium) {
    padding-right: $space-medium;
  }
  @media (min-height: $large) {
    padding-right: 40px;
  }
}
.hamburger-line {
  transition: fill 0.3s ease;
}
#hamburger:hover {
  cursor: pointer;
  .hamburger-line {
    fill: $nav-link-hover;
  }
}

// header
#header {
  //transition: height 0.5s ease;
  //flex-grow: 0;
  //flex-shrink: 0;
  //display: "flex";
  //gap: "medium";
  //flex-direction: "column";
  //background-color: "#050507";
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  //width: calc(100% - #{$space-small} - #{$space-small} - 15px);
  //@media (min-width: $small) {
  //  width: calc(100% - 45px - 45px);
  //}
  //@media (min-width: $medium) {
  //  width: calc(100% - #{$space-medium} - #{$space-medium});
  //}
  z-index: 13;
  height: 41px;
  /**
  padding: 15px $space-small;
  @media (min-width: $small) {
    padding: 15px 45px;
  }
  @media (min-width: $medium) {
    padding: 15px $space-medium;
  }
  @media (min-height: $large) {
    width: calc(100% - 80px);
    padding: 30px 40px;
  }
  **/
  padding: 15px 0;
  @media (min-width: $small) {
    padding: 15px 0px;
  }
  @media (min-width: $medium) {
    padding: 15px 0;
  }
  @media (min-height: $large) {
    padding: 30px 0px;
  }
}

.headerOpen {
  z-index: 14 !important;
  //height: 100%;
  //width: 100%;
  //position: absolute;
  background: rgb(20,13,64);
  background: linear-gradient(90deg, rgba(20,13,64,1) 0%, rgba(51,19,57,1) 50%, rgba(80,25,51,1) 100%);
}

// Nav Content
#navContent {
  width: 100%;
  //height: 100%;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  @media (min-width: $medium) {
    flex-direction: row;
  }
  align-items: center;
}
#hof_logo {
  width: 50%;
  @media (min-width: $medium) {
    width: 75%;
  }
}

#navContentFooter {
  color: #fff;
  display: flex;
  flex: 0 0 70px;
  flex-direction: column-reverse;
  @media (min-width: $large) {
    flex-direction: row;
  }
  justify-content: space-between;
  align-items: center;
  padding: 0 $space-small 35px $space-small;
  @media (min-width: $medium) {
    padding: 0 $space-medium 35px $space-medium;
  }
  @media (min-width: $large) {
    padding: 0 $space-medium 35px $space-medium;
  }
  font-size: 14px;
  @media (max-width: $max-small) {
    font-size: 14px;
    flex: 0 1 100px;
    flex-direction: column-reverse;
    justify-content: flex-start;
  }
}
#navContentFooterCopyright {
  font-size: 14px;
  font-weight: bold;
}
@media (max-width: $max-medium) {
  #navContentFooterCopyright {
    //width: 50%;
  }
}

#navContentFooterLinks {
  @media (max-width: $max-medium) {
    margin: $space-small 0;
  }
  font-size: 14px;
  a {
    color: #fff;
    opacity: 0.8;
    text-decoration: none;
    margin-right: 10px;
  }
  a:hover {
    opacity: 1;
    color: $nav-link-hover;
  }

  .LanguageSelector, a {
    margin-right: 10px !important;
  }

  @media (min-width: $small) {
    .LanguageSelector, a {
      margin-right: 10px !important;
    }
  }
  @media (min-width: $medium) {
    .LanguageSelector, a {
      margin-left: 10px !important;
    }
  }
  @media (min-width: $large) {
    .LanguageSelector, a {
      margin-left: 15px !important;
    }
  }
  @media (min-width: $xlarge) {
    .LanguageSelector, a {
      margin-left: 25px !important;
    }
  }
}

#navContentFooterSocial {
  a {
    color: #fff;
    opacity: 0.8;
    text-decoration: none;
    margin-right: $space-small;
    font-size: 20px;
  }
  a:hover {
    opacity: 1;
    color: $nav-link-hover;
  }
}
@media (min-width: $small) {
  #navContentFooterSocial {
    a {
      font-size: 24px;
      margin-right: 10px;
    }
  }
}
@media (min-width: $medium) {
  #navContentFooterSocial {
    a {
      font-size: 24px;
      margin-left: 10px;
    }
  }
}
@media (min-width: $medium) {
  #navContentFooterSocial {
    a {
      font-size: 24px;
      margin-left: 15px;
    }
  }
}
@media (min-width: $xlarge) {
  #navContentFooterSocial {
    a {
      font-size: 32px;
      margin-left: 25px;
    }
  }
}


#mainNavBottom {
  flex: 1 0;
}
.navContentCol {
  flex: 1 1;
  text-align: center;
}

// The actual menu links
#navLinks {
  a {
    font-weight: 300;
    @include font-abril;
    @media (min-width: $small) {
        font-size: 24px;
        line-height: 32px;
    }
    @media (min-width: $medium) {
        font-size: 32px;
        line-height: 40px;
    }
    @media (min-width: $large) {
        font-size: 32px;
        line-height: 40px;
    }
    @media (min-width: $xlarge) {
        font-size: 48px;
        line-height: 60px;
    }
    text-decoration: none;
    transition: opacity 0.2s ease, color 0.2s ease;
    display: block;
    color: $nav-link;
    opacity: 0.8;
    @media (max-width: $small) {
      font-size: 24px;
      line-height: 30px;
    }
  }


  a:hover {
    color: $nav-link-hover;
    opacity: 1;
  }
  #navLinksContainer {
    width: 100%;
    @media (min-width: $medium) {
      text-align: right;
    }
  }
  @media (min-width: $medium) {
    #navLinksContainer {
      width: 95%;
    }
  }
  @media (min-width: $xlarge) {
    #navLinksContainer {
      width: 90%;
    }
  }
}
